import axiosInstance from "./api";
import TokenService from "./token.service";
// import AuthService from "@/services/auth.service";

const setup = (app) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken();
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
        config.headers["Access-Control-Allow_Origin"] = "Bearer " + token; // for Spring Boot back-end
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const error_code = err.code;
      if (error_code == "ERR_BAD_REQUEST") {
        TokenService.clearUser();
      }
      const originalConfig = err.config;
      if (originalConfig.url === "/login") return Promise.reject(err);

      const kind = err.response?.data?.detail?.kind;

      if (kind === "subscription expired") {
        app.config.globalProperties.$toast.add({
          severity: "warn",
          summary: err.response.statusText,
          detail: err.response?.data?.detail.msg,
          life: 3000,
        });
        console.log("Redirect to pay for subscription here?");
        return;
      }

      return Promise.reject(err);
    }
  );
};
export default setup;
