import { createRouter, createWebHistory } from "vue-router";
import TokenService from "@/services/token.service";
import HomeView from "../views/HomeView.vue";
import AdminView from "../views/AdminView.vue";

import LoginView from "@/views/LoginView.vue";
import ResetPassword from "@/views/ResetPasswordView.vue";

import createAccountView from "@/views/CreateAccount.vue";
const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "Home",
      requiresAuth: true,
    },
  },
  {
    path: "/administrador",
    name: "admin",
    component: AdminView,
    meta: {
      title: "Admin",
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      title: "Iniciar sesion",
    },
  },
  {
    path: "/reset-password",
    name: "reset",
    component: ResetPassword,
    meta: {
      title: "Cambiar contraseña",
    },
  },
  {
    path: "/create-account",
    name: "createAccount",
    component: createAccountView,
    meta: {
      title: "Create Account",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} | DyM`;
  next();
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isGuestOnly = to.matched.some((record) => record.meta.guestOnly);
  const requiresAdmin = to.matched.some((record) => record.meta.admin);

  const currentUser = TokenService.getUser();
  const admin = TokenService.getLocalRole();
  const temp = TokenService.getLocalTemp();

  if (requiresAuth && temp && currentUser) {
    next("/reset-password");
  } else if (requiresAuth && !currentUser) {
    next("/login");
  } else if (isGuestOnly && currentUser) {
    next("/home");
  } else if (requiresAdmin && admin !== "admin") {
    next("/login");
  } else {
    next();
  }
});

export default router;
