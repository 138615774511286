import api from "./api";

class ForecastService {
  get() {
    return api.postData("/forecast/get_files");
  }
  export(id) {
    api
      .post("/forecast/download", "", {
        params: { id: id },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "forecast.csv");
        document.body.appendChild(link);
        link.click();
      });
  }
}

export default new ForecastService();
