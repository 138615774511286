<template>
  <Toast position="bottom-right" />
  <div class="grid surface-section text-800">
    <router-view v-slot="{ Component }">
      <transition name="page-opacity" mode="out-in">
        <component :is="Component" :key="$route.path" />
      </transition>
    </router-view>
  </div>
</template>
<script>
import EventBus from "./common/EventBus";
import AuthService from "@/services/auth.service";

export default {
  components: {},
  data() {
    return {
      navigation: false,
    };
  },
  created() {},
  mounted() {
    EventBus.on("logout", () => {
      this.logOut();
    });
  },
  beforeUnmount() {
    EventBus.remove("logout");
  },

  methods: {
    logOut() {
      AuthService.logout();
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss">
// colors
$main-color: #031624;
$secondary-color: rgb(145, 149, 197);
$accent-color: #5c4bda;
$accent-color-two: #e1c940;
$neutral-two: #575777;
$neutral-color: #f2f2f2;

@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "IBM Plex Sans", sans-serif;
}
form.register {
  background-color: $neutral-color;
}
body {
  background-color: $neutral-color;
  overflow-x: hidden !important;
}
.btn {
  display: inline;
  border: none;
  font-size: 1rem;
  font-weight: 400;
  color: black;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid;
  text-transform: uppercase;
  &:focus {
    outline: none;
  }
  &:hover {
    color: rgba(48, 48, 48, 0.7);
    transition: all 0.3s ease-in-out;
    &:hover {
      transform: scale(1.01);
    }
  }
}
.download_btn {
  color: $neutral-color;
  background-color: $accent-color;
}
.btn.cta {
  color: $accent-color;
  border-color: $accent-color;
}
.btn.ctaTwo {
  color: $neutral-two;
  border-color: $neutral-two;
}
.btn.ctaThree {
  color: $main-color;
  border-color: $main-color;
}
h1 {
  color: $main-color;
  span {
    text-decoration: underline;
    color: $accent-color;
  }
}

.img-pop {
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.01);
  }
}
.p-scrollpanel-bar .p-scrollpanel-bar-y {
  background-color: #1976d2 !important;
  opacity: 1;
  transition: background-color 0.3s;
}

.p-scrollpanel-bar:hover {
  background-color: #135ba1;
}

// Page Transition

.page-opacity-enter-active,
.page-opacity-leave-active {
  transition: 350ms ease all;
}

.page-opacity-enter-from,
.page-opacity-leave-to {
  opacity: 0;
}
.primaryBtn {
  background-color: $accent-color !important;
  border-color: $secondary-color !important;
}
.form-wrap {
  background-color: #031624;
  overflow: hidden;
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 90%;
  @media (min-width: 900px) {
    width: 100%;
  }
  .register {
    max-width: 550px;
    height: 750px;
    box-shadow: rgba(218, 217, 217, 0.16) 0px 10px 36px 0px,
      rgba(218, 217, 217, 0.16) 0px 0px 0px 1px;
    h2 {
      max-width: 350px;
    }
  }
  .login-register {
    margin-bottom: 32px;
    .router-link {
      color: #ff4d3d;
    }
  }
  form {
    padding: 0 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    @media (min-width: 900px) {
      padding: 0 50px;
    }
    h4 {
      text-align: center;
      margin-bottom: 40px;
      @media (min-width: 900px) {
        font-size: 40px;
      }
    }
    .inputs {
      width: 100%;
      max-width: 350px;
      .input {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 8px;
        input {
          width: 100%;
          border: none;
          background-color: #f2f7f6;
          padding: 4px 4px 4px 30px;
          height: 50px;
          &:focus {
            outline: none;
          }
        }
        .icon {
          width: 12px;
          position: absolute;
          left: 6px;
        }
      }
    }
    .forgot-password {
      text-decoration: none;
      color: #000;
      cursor: pointer;
      font-size: 14px;
      margin: 48px 0 32px;
      border-bottom: 1px solid transparent;
      transition: 0.5s ease all;
      &:hover {
        border-color: #303030;
      }
    }
  }
  .background {
    flex: 2;
    background-image: url("@/assets/logo.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    @media (max-width: 900px) {
      display: none;
    }
    h1 {
      color: #f2f2f2;
    }
  }
}
.signInBtn {
  background-color: orange;
  transition: 0.5s ease all;
  padding: 6px 24px;
  font-size: 14px;
  font-weight: 400;
}
.error {
  color: #e2000b;
  text-align: center;
}
</style>
