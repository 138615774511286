import api from "./api";

class HistoricaService {
  get_meta() {
    return api.getData("/historica/get_meta");
  }
  get_base() {
    api.postData("/historica/get_historica");
  }
  export(id) {
    api
      .post("/forecast/download", "", {
        params: { id: id },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "forecast.csv");
        document.body.appendChild(link);
        link.click();
      });
  }
}

export default new HistoricaService();
