import api from "./api";
class UserService {
  get(id) {
    return api.getData("/user/get", { params: { id: id } });
  }
  get_all() {
    return api.getData("/user/get_all");
  }
  delete(id) {
    return api.deleteData("/user/delete", { params: { id: id } });
  }
  create(email, password, role) {
    return api.postData("/user/create", {
      email: email,
      password: password,
      role: role,
    });
  }
  forgot_password(email) {
    return api.postData("/forgot_password", { email });
  }
  reset_password(password) {
    return api.postData("/user/forgot_password", "", { password });
  }
  send_verify_email(email) {
    return api.post("/user/send_verification_email", {
      email,
      base_url: window.location.origin + "/verify",
    });
  }
  verify_email(token) {
    return api.post("/user/verify_email", null, { params: { token } });
  }
}
export default new UserService();
