<template>
  <div>
    <Loading v-if="loading" />
    <Modal
      v-if="modalActive"
      :modalMessage="'Al finalizar de crear el forecast, recibira una notificacion en su correo electronico'"
      :type="'forecast'"
      @close-modal="showModal()"
      @confirm="runForecast()"
    />

    <dashboard-layout v-if="!loading">
      <div class="flex justify-between items-center mb-6">
        <h5>Forecast</h5>
      </div>
      <hr class="mb-12" />
      <div
        class="grid grid-cols-2 gap-4 w-full rounded bg-white shadow-lg p-3 py-10"
        v-if="!mobile"
      >
        <div class="flex flex-col items-center border-r">
          <h6>Decargar forecast actualizado</h6>
          <small class="italic text-slate-500 mb-8"> actualizado </small>
          <!-- info -->
          <div class="flex flex-col border-b p-3 w-1/2 items-center">
            <p class="mb-2">Base historica</p>
            <h6>
              {{ this.min_month }}/{{ this.min_year }} - {{ this.max_month }}/{{
                this.max_year
              }}
            </h6>
          </div>
          <div class="flex flex-col border-b p-3 w-1/2 items-center">
            <p class="mb-2">Base cierre de mes</p>
            <h6>
              {{ this.cierre_week }}
            </h6>
          </div>
          <div class="flex flex-col border-b p-3 w-1/2 items-center mb-8">
            <p class="mb-2">Base completa proyeccion</p>
            <h6>
              {{ this.max_month }}/{{ this.max_year }} -
              {{ this.forecasted_month }}/{{ this.forecasted_year + 1 }}
            </h6>
          </div>

          <!-- Acciones -->
          <div class="flex flex-col">
            <button class="btn cta mb-2" @click="descargarBaseEntera()">
              Descargar base historica
              <i
                class="pi pi-spin pi-spinner"
                v-if="this.tipeLoading === 'entera'"
              ></i>
            </button>
            <button class="btn ctaTwo mb-2" @click="descargarCierre()">
              Descargar cierre de mes
              <i
                class="pi pi-spin pi-spinner"
                v-if="this.tipeLoading === 'cierre'"
              ></i>
            </button>
            <button class="btn ctaThree mb-2" @click="this.showModal()">
              Actualizar base completa
              <i class="pi pi-spin pi-spinner" v-if="this.forecastLoading"></i>
            </button>
          </div>
        </div>
        <div class="flex flex-col items-center">
          <h6 class="mb-8">Decargar forecast anteriores</h6>
          <div class="forecastPanel flex flex-col w-full">
            <div
              class="flex items-center justify-between py-4 px-12 w-full mb-4"
              v-for="(item, index) in this.old_forecasts"
              :key="index"
            >
              <div class="flex flex-col">
                <div class="flex">
                  <p class="font-semibold mr-1">Nombre archivo:</p>
                  <p>Forecast</p>
                </div>
                <div class="flex">
                  <p class="font-semibold mr-1">Fecha:</p>
                  <p>{{ item.created_at }}</p>
                </div>
                <div class="flex">
                  <p class="font-semibold mr-1">Creado por:</p>
                  <p>{{ item.user_email["email"] }}</p>
                </div>
              </div>
              <div
                class="p-3 rounded download_btn cursor-pointer hover:shadow"
                @click="download(item.id)"
              >
                <i class="pi pi-download" style="font-size: 1.2rem"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TabsWrapper v-else>
        <Tab title="Actualizado">
          <div class="flex flex-col items-center">
            <h6>Decargar forecast actualizado</h6>
            <small class="italic text-slate-500 mb-8"> actualizado </small>
            <!-- info -->
            <div class="flex flex-col border-b p-3 w-full items-center">
              <p class="mb-2">Base historica</p>
              <h6>
                {{ this.min_month }}/{{ this.min_year }} -
                {{ this.max_month }}/{{ this.max_year }}
              </h6>
            </div>
            <div class="flex flex-col border-b p-3 w-full items-center">
              <p class="mb-2">Base cierre de mes</p>
              <h6>
                {{ this.cierre_week }}
              </h6>
            </div>
            <div class="flex flex-col border-b p-3 w-full items-center mb-8">
              <p class="mb-2">Base completa proyeccion</p>
              <h6>
                {{ this.max_month }}/{{ this.max_year }} -
                {{ this.forecasted_month }}/{{ this.forecasted_year + 1 }}
              </h6>
            </div>

            <!-- Acciones -->
            <div class="flex flex-col">
              <button class="btn cta mb-2" @click="descargarBaseEntera()">
                Descargar base historica
                <i
                  class="pi pi-spin pi-spinner"
                  v-if="this.tipeLoading === 'entera'"
                ></i>
              </button>
              <button class="btn ctaTwo mb-2" @click="descargarCierre()">
                Descargar cierre de mes
                <i
                  class="pi pi-spin pi-spinner"
                  v-if="this.tipeLoading === 'cierre'"
                ></i>
              </button>

              <button class="btn ctaThree mb-2" @click="this.showModal()">
                Actualizar base completa
                <i
                  class="pi pi-spin pi-spinner"
                  v-if="this.forecastLoading"
                ></i>
              </button>
            </div>
          </div>
        </Tab>
        <Tab title="Anterior">
          <div class="flex flex-col items-center">
            <h6 class="mb-8">Decargar forecast anteriores</h6>
            <div class="forecastPanel flex flex-col w-full">
              <div
                class="flex items-center justify-between w-full mb-4"
                v-for="(item, index) in this.old_forecasts"
                :key="index"
              >
                <div class="flex flex-col mr-4">
                  <div class="flex flex-col">
                    <p class="font-semibold mr-1">Nombre archivo:</p>
                    <p>Forecast</p>
                  </div>
                  <div class="flex flex-col">
                    <p class="font-semibold mr-1">Fecha:</p>
                    <p>{{ item.created_at }}</p>
                  </div>
                  <div class="flex flex-col">
                    <p class="font-semibold mr-1">Creado por:</p>
                    <p>{{ item.user_email["email"] }}</p>
                  </div>
                </div>
                <div
                  class="p-3 rounded download_btn cursor-pointer hover:shadow"
                  @click="download(item.id)"
                >
                  <i class="pi pi-download" style="font-size: 1.2rem"></i>
                </div>
              </div>
            </div>
          </div>
        </Tab>
      </TabsWrapper>
    </dashboard-layout>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
import dashboardLayout from "@/layouts/Layout";
import ForecastService from "@/services/forecast.service";
import UserService from "@/services/user.service";
import HistoricaService from "@/services/historica.service";
import api from "@/services/api";
import Loading from "@/components/Loading.vue";
import TabsWrapper from "@/components/TabsWrapper.vue";
import Tab from "@/components/TabComponent.vue";
export default {
  name: "HomeView",
  components: { dashboardLayout, Loading, TabsWrapper, Tab, Modal },
  data() {
    return {
      windowWidth: null,
      mobile: null,
      loading: null,
      old_forecasts: null,
      max_month: null,
      max_year: null,
      min_year: null,
      min_month: null,
      forecasted_year: null,
      forecasted_month: null,
      forecastLoading: null,
      tipeLoading: null,
      modalActive: null,
      historica: null,
      cierre_week: null,
    };
  },
  computed: {
    meta() {
      return this.meta_data;
    },
  },
  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
    this.get_old_forecasts();
    this.loading = true;
  },
  mounted() {},
  methods: {
    showModal() {
      this.modalActive = !this.modalActive;
    },
    async runForecast() {
      this.showModal();
      this.forecastLoading = true;
      let forecast = await this.create_export();
      return forecast;
    },
    async descargarBaseEntera() {
      this.tipeLoading = "entera";
      let historica = await this.export_file(
        "/historica/get_historica",
        "base_historica"
      );
      this.tipeLoading = null;

      return historica;
    },
    async descargarCierre() {
      this.tipeLoading = "cierre";
      let cierre = await this.export_file("/historica/get_cierre", "cierre");
      this.tipeLoading = null;
      return cierre;
    },
    async getBaseEntera() {
      await HistoricaService.get_base().then((res) => {
        this.historica = res;
      });
    },
    async get_old_forecasts() {
      await ForecastService.get()
        .then((res) => {
          this.old_forecasts = res;
        })
        .then(() => {
          this.get_user();
          this.get_meta();
        });
      this.loading = false;
    },

    async get_user() {
      let forecast = await this.old_forecasts;
      for (let i = 0; i < forecast.length; i++) {
        this.old_forecasts[i]["user_email"] = await UserService.get(
          this.old_forecasts[i]["user_id"]
        );
      }
      console.log(this.old_forecasts);
    },
    async get_meta() {
      await HistoricaService.get_meta().then((res) => {
        this.max_year = res["max_year"];
        this.max_month = res["max_month"];
        this.min_year = res["min_year"];
        this.min_month = res["min_month"];
        this.forecasted_year = res["forecasted_year"];
        this.forecasted_month = res["forecasted_month"];
        this.cierre_week = res["cierre_week"];
      });
    },
    async download(id) {
      return await ForecastService.export(id);
    },
    export_file(endpoint, file_name) {
      api.post(endpoint).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        this.tipeLoading = null;
        this.get_old_forecasts();
        link.href = url;
        link.setAttribute("download", `${file_name}.csv`);
        document.body.appendChild(link);
        link.click();
      });
    },
    create_export() {
      this.$store.dispatch("run_forecast").then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        this.forecastLoading = false;
        this.get_old_forecasts();
        link.href = url;
        link.setAttribute("download", "forecast.csv");
        document.body.appendChild(link);
        link.click();
      });
    },
    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 750) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
    },
  },
};
</script>

<style lang="scss" scoped>
li {
  padding-bottom: 1rem;
}
.forecastPanel {
  max-height: 600px !important;
  overflow-y: auto;
}
</style>
