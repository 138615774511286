<template>
  <div>
    <Loading v-if="loading" />
    <Modal
      v-if="modalActive"
      :modalMessage="this.modalMsg"
      :type="'user'"
      @close-modal="showModal()"
      @confirmDelete="deleteUser()"
    />

    <dashboard-layout v-if="!loading">
      <div class="flex justify-between items-center mb-6">
        <h5>Administrador</h5>
      </div>
      <hr class="mb-12" />
      <TabsWrapper>
        <Tab title="Usuarios">
          <div class="flex flex-col">
            <h6 class="text-left mb-2">Manejar usuarios</h6>
            <hr class="mb-6" />
            <div
              class="flex p-3 items-center"
              v-for="(user, index) in users"
              :key="index"
            >
              <div class="w-1/8 mr-4">
                <i class="pi pi-user"></i>
              </div>
              <div class="flex justify-between w-full">
                <div class="flex flex-col">
                  <div class="flex mb-1">
                    <p class="font-semibold mr-1">Correo:</p>
                    <p>{{ user.email }}</p>
                  </div>
                  <div class="flex mb-1">
                    <p class="font-semibold mr-1">Role:</p>
                    <p>{{ user.role }}</p>
                  </div>
                </div>
                <div class="flex items-center">
                  <Button
                    label="Cambiar contraseña"
                    icon="'pi pi-spin pi-spinner'"
                    iconPos="right"
                    class="btn-act p-button-info"
                    @click="sendResetPassword(user.email)"
                  />
                  <Button
                    label="Eliminar"
                    icon="pi pi-trash"
                    iconPos="right"
                    class="p-button-danger"
                    @click="deleteThis(user)"
                  />
                </div>
              </div>
            </div>
          </div>
        </Tab>
        <Tab title="Agregar">
          <div class="flex flex-col">
            <h6 class="text-left mb-2">Agregar usuario</h6>
            <hr class="mb-6" />
            <form class="rounded">
              <div class="inputs">
                <div class="input">
                  <div class="flex flex-col w-full">
                    <label for="email">Correo electronico:</label>
                    <input type="email" class="rounded" v-model="correo" />
                  </div>
                </div>
                <div class="input">
                  <div class="flex flex-col w-full">
                    <label for="role">Rol/permisos:</label>
                    <select id="role" v-model="role" class="select rounded">
                      <option value="" disabled selected>
                        Seleccionar rol
                      </option>
                      <option
                        v-for="(rol, index) in roles"
                        :key="index"
                        :value="rol.value"
                      >
                        {{ rol.tag }}
                      </option>
                    </select>
                  </div>
                </div>
                <div v-show="successMsg" class="error">{{ this.errorMsg }}</div>
              </div>
              <button @click.prevent="this.createUser()" class="btn mt-6">
                crear cuenta
                <i
                  class="pi pi-spin pi-spinner"
                  v-if="this.tipeLoading === 'cuenta'"
                ></i>
                <i class="pi pi-check" v-if="this.tipeLoading === 'check'"></i>
              </button>
              <br />
            </form>
          </div>
        </Tab>
      </TabsWrapper>
    </dashboard-layout>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
import dashboardLayout from "@/layouts/Layout";
import UserService from "@/services/user.service";
import Loading from "@/components/Loading.vue";
import TabsWrapper from "@/components/TabsWrapper.vue";
import Tab from "@/components/TabComponent.vue";
export default {
  name: "AdminView",
  components: { dashboardLayout, Loading, TabsWrapper, Tab, Modal },
  data() {
    return {
      windowWidth: null,
      mobile: null,
      loading: null,
      modalActive: null,
      users: null,
      toDelete: null,
      email: null,
      correo: null,
      modalMsg: null,
      successMsg: null,
      tipeLoading: null,

      role: null,
      roles: [
        { value: "user", tag: "Usuario" },
        { value: "admin", tag: "Administrador" },
      ],
    };
  },
  computed: {
    meta() {
      return this.meta_data;
    },
  },
  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
    this.get_users();
    this.loading = true;
  },
  mounted() {},
  methods: {
    generatePassword(length) {
      const chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=";

      let password = "";
      for (let i = 0; i < length; i++) {
        // Choose a random character from the chars string
        const randomIndex = Math.floor(Math.random() * chars.length);
        password += chars[randomIndex];
      }
      return `${password}P90P!#`;
    },
    showModal() {
      this.modalActive = !this.modalActive;
    },
    deleteThis(id) {
      this.toDelete = id;
      this.modalMsg =
        "Una vez eliminado, este usuario no tendra mas acceso a la plataforma";
      this.showModal();
    },
    resetPassword(email) {
      this.email = email;
    },
    async createUser() {
      let password = this.generatePassword(12);
      this.tipeLoading = "cuenta";

      await UserService.create(this.correo, password, this.role).then(() => {
        this.tipeLoading = "check";

        this.successMsg = "Usuario creado exitosamente";
        this.get_users();
        setTimeout(() => {
          this.tipeLoading = null;

          this.$store.dispatch("forgot_password", this.correo);
        }, 8000);
      });
    },
    async sendResetPassword(email) {
      await this.$store.dispatch("forgot_password", email).then(() => {
        this.showModal();
        this.modalMsg = `Correo enviado exitosamente a: ${email}`;
        setTimeout(() => {
          this.showModal();
        }, 2000);
      });
    },
    async deleteUser() {
      await UserService.delete(this.toDelete.id).then(() => {
        this.users.pop(this.toDelete);
        this.showModal();
      });
    },
    async get_users() {
      await UserService.get_all().then((res) => {
        this.users = res;
        this.loading = false;
      });
    },

    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 750) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
    },
  },
};
</script>

<style lang="scss" scoped>
li {
  padding-bottom: 1rem;
}
.forecastPanel {
  max-height: 600px !important;
  overflow-y: auto;
}
.btn-act {
  margin-right: 6px !important;
}
form {
  padding: 0 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  @media (min-width: 900px) {
    padding: 0 50px;
  }
  h4 {
    text-align: center;
    margin-bottom: 40px;
    @media (min-width: 900px) {
      font-size: 40px;
    }
  }
  .inputs {
    width: 100%;
    max-width: 350px;
    .input {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 8px;
      input {
        width: 100%;
        border: none;
        background-color: #f2f7f6;
        padding: 4px 4px 4px 30px;
        height: 50px;
        &:focus {
          outline: none;
        }
      }
      .icon {
        width: 12px;
        position: absolute;
        left: 6px;
      }
    }
  }
  .forgot-password {
    text-decoration: none;
    color: #000;
    cursor: pointer;
    font-size: 14px;
    margin: 48px 0 32px;
    border-bottom: 1px solid transparent;
    transition: 0.5s ease all;
    &:hover {
      border-color: #303030;
    }
  }
}
select {
  width: 100%;
  border: none;
  background-color: #f2f7f6;
  padding: 4px 4px 4px 4px;
  height: 50px;
  &:focus {
    outline: none;
  }
}
</style>
